import React, { useState, useEffect } from "react";
import PlanTable from "../components/dashboard/PlansTable";
import LoadingButton from "../components/ui/LoadingBtn";
import Modals from "../components/Modals";
import { Button, Form, Spinner } from "react-bootstrap";
import usePlanForm from "../hooks/usePlanForm";
import { Col, Row } from "reactstrap";
import { useUpdatePlan } from "../hooks/useFetchPlans";

const Plans = () => {
  const [modalShow, setModalShow] = useState(false);
  const [editPlan, setEditPlan] = useState(null);
  const {
    mutate: addPlan,
    isLoading,
    isSuccess,
    isError,
    error,
  } = usePlanForm();

  const {
    mutate: updatePlan,
    isLoading: isUpdating,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
    error: updateError,
  } = useUpdatePlan();

  const handleModalSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    const payload = {
      name: formData.get("name").trim(),
      monthly_price: parseFloat(formData.get("monthly_price").trim()),
      annual_price: parseFloat(formData.get("annual_price").trim()),
      max_projects: parseInt(formData.get("max_projects").trim()),
      max_users: parseInt(formData.get("max_users").trim()),
      max_parallel_runs: parseInt(formData.get("max_parallel_runs").trim()),
      max_monthly_remote_runs: parseInt(
        formData.get("max_monthly_remote_runs").trim()
      ),
      max_mobile_runtime: parseInt(formData.get("max_mobile_runtime").trim()),
      turbo_mode: formData.get("turbo_mode"),
    };

    if (editPlan) {
      updatePlan({ ...payload, subscription_plan_id: editPlan.id });
    } else {
      addPlan(payload);
    }
  };

  useEffect(() => {
    if (isSuccess || isUpdateSuccess) {
      setTimeout(() => {
        setModalShow(false);
        setEditPlan(null);
      }, 1000);
    }
  }, [isSuccess, isUpdateSuccess]);

  return (
    <div className="admin_container">
      <div className="add_admin">
        <LoadingButton
          isInitial={editPlan ? `Edit Plan` : `Add Plan`}
          icon={<i className="bi bi-person-add"></i>}
          className={"justify-content-end"}
          onClick={() => {
            setModalShow(true);
            setEditPlan(null); // Reset edit state when opening the modal for creating a new plan
          }}
        />
      </div>
      <Modals
        show={modalShow}
        title={editPlan ? "Edit Plan" : "Create Plan"}
        onHide={() => {
          setModalShow(false);
          setEditPlan(null);
        }}
      >
        <Form onSubmit={handleModalSubmit}>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Subscription plan name"
              name="name"
              autoFocus
              required
              defaultValue={editPlan?.name || ""}
            />
          </Form.Group>

          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Monthly Price</Form.Label>
                <Form.Control
                  type="number"
                  step="any"
                  placeholder="299"
                  name="monthly_price"
                  required
                  defaultValue={editPlan?.monthly_price || ""}
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Yearly Price</Form.Label>
                <Form.Control
                  type="number"
                  step="any"
                  placeholder="299"
                  name="annual_price"
                  required
                  defaultValue={editPlan?.annual_price || ""}
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Maximum Projects</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="10"
                  name="max_projects"
                  required
                  defaultValue={editPlan?.max_projects || ""}
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Maximum Users</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="10"
                  name="max_users"
                  required
                  defaultValue={editPlan?.max_users || ""}
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Maximum Parallel Runs</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="10"
                  name="max_parallel_runs"
                  required
                  defaultValue={editPlan?.max_parallel_runs || ""}
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Maximum Monthly Remote Runs</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="10"
                  name="max_monthly_remote_runs"
                  required
                  defaultValue={editPlan?.max_monthly_remote_runs || ""}
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Maximum Mobile Runtime</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="10"
                  name="max_mobile_runtime"
                  required
                  defaultValue={editPlan?.max_mobile_runtime || ""}
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Turbo Mode</Form.Label>
                <Form.Control
                  as="select"
                  name="turbo_mode"
                  required
                  defaultValue={editPlan?.turbo_mode ? "1" : "0"}
                >
                  <option disabled>Select an option</option>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <div className="add_admin_modal">
            <small
              style={{ color: isSuccess || isUpdateSuccess ? "green" : "red" }}
            >
              {isError || isUpdateError
                ? error?.message || updateError?.message
                : isSuccess || isUpdateSuccess
                ? "Plan updated successfully"
                : null}
            </small>
            <Button
              disabled={isLoading || isUpdating}
              variant={
                isSuccess || isUpdateSuccess
                  ? "outline-success"
                  : isError || isUpdateError
                  ? "outline-danger"
                  : "outline-primary"
              }
              type="submit"
            >
              {isLoading || isUpdating ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span> Please wait...</span>
                </>
              ) : editPlan ? (
                "Save Changes"
              ) : (
                "Add Plan"
              )}
            </Button>
          </div>
        </Form>
      </Modals>

      <PlanTable
        onEditPlan={(plan) => {
          setEditPlan(plan);
          setModalShow(true);
        }}
      />
    </div>
  );
};

export default Plans;
