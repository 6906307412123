import React from "react";
import SubscriptionTable from "../components/dashboard/SubscriptionsTable";

const Subscriptions = () => {
  return (
    <div className="admin_container">
      <SubscriptionTable />
    </div>
  );
};

export default Subscriptions;
