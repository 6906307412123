import metricssApiClient, { errorMessage } from "../utils/axios-utils";

const url = `/metrics`;

export const fetchAllMetrics = async (startDate = null, endDate = null) => {
  try {
    const getMetrics = await metricssApiClient().get(`${url}/`, {
      params: { date_from: startDate, date_to: endDate },
    });
    return await getMetrics?.data;
  } catch (error) {
    console.error("Error fetching metrics:", error);
    errorMessage(error);
  }
};

export const fetchActiveUsersCount = async () => {
  try {
    let path = "active-users";
    const getActiveUsersCount = await metricssApiClient().get(`${url}/${path}`);
    return await getActiveUsersCount?.data;
  } catch (error) {
    errorMessage(error);
  }
};

export const getUsers = async (
  pageNumber,
  searchQuery,
  sortBy,
  sortDirection,
  startDate,
  endDate
) => {
  try {
    const queryParameters = [
      `page=${pageNumber}`,
      sortBy ? `order_by=${sortBy}` : "",
      sortDirection ? `order_dir=${sortDirection}` : "",
      startDate ? `date_from=${startDate}` : "",
      endDate ? `date_to=${endDate}` : "",
    ]
      .filter(Boolean)
      .join("&");

    const urlWithQuery = `${url}/users${
      searchQuery
        ? `?${queryParameters}&search=${searchQuery}`
        : `?${queryParameters}`
    }`;
    const response = await metricssApiClient().get(urlWithQuery);

    return await response?.data;
  } catch (error) {
    errorMessage(error);
  }
};

export const findOneUser = async ({ queryKey }) => {
  try {
    const userId = queryKey[1];
    const findOne = await metricssApiClient().get(`${url}/users/${userId}`);
    return findOne?.data;
  } catch (error) {
    errorMessage(error);
  }
};

export const findOneUserProjects = async (
  userId,
  pageNumber,
  startDate,
  endDate
) => {
  try {
    const findOne = await metricssApiClient().get(
      `${url}/users/${userId}/projects?page=${pageNumber}&date_to=${endDate}${
        startDate ? `&date_from=${startDate}` : ""
      }`
    );
    return findOne?.data;
  } catch (error) {
    errorMessage(error);
  }
};

export const findOneUserFeatures = async ({ queryKey }) => {
  try {
    const [, userId] = queryKey;
    const res = await metricssApiClient().get(
      `${url}/users/${userId}/features`
    );

    return res?.data;
  } catch (error) {
    errorMessage(error);
  }
};

export const assignUserFeatures = async ({ userId, features }) => {
  try {
    const res = await metricssApiClient().post(
      `${url}/users/${userId}/features`,
      { features }
    );

    return res?.data;
  } catch (error) {
    errorMessage(error);
  }
};

export const findOneUserTestCases = async (
  userId,
  PageNumber,
  startDate,
  endDate,
  selectedSortDirection
) => {
  try {
    const findOne = await metricssApiClient().get(
      `${url}/users/${userId}/test-cases?page=${PageNumber}&date_to=${endDate}${
        startDate ? `&date_from=${startDate}` : ""
      }&order=${selectedSortDirection}`
    );
    return findOne?.data;
  } catch (error) {
    errorMessage(error);
  }
};

export const findOneUserTestSuites = async (
  userId,
  PageNumber,
  startDate,
  endDate
) => {
  try {
    const findOne = await metricssApiClient().get(
      `${url}/users/${userId}/test-suites?page=${PageNumber}&date_to=${endDate}${
        startDate ? `&date_from=${startDate}` : ""
      }`
    );
    return findOne?.data;
  } catch (error) {
    errorMessage(error);
  }
};

export const findOneUserApiSuites = async (
  userId,
  PageNumber,
  startDate,
  endDate
) => {
  try {
    const findOne = await metricssApiClient().get(
      `${url}/users/${userId}/api-suites?page=${PageNumber}&date_to=${endDate}${
        startDate ? `&date_from=${startDate}` : ""
      }`
    );
    return findOne?.data;
  } catch (error) {
    errorMessage(error);
  }
};

export const findOneUserSchedules = async (
  userId,
  PageNumber,
  startDate,
  endDate
) => {
  try {
    const findOne = await metricssApiClient().get(
      `${url}/users/${userId}/schedules?page=${PageNumber}&date_to=${endDate}${
        startDate ? `&date_from=${startDate}` : ""
      }`
    );
    return findOne?.data;
  } catch (error) {
    errorMessage(error);
  }
};
