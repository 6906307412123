import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Table,
  Row,
  Col,
} from "reactstrap";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import moment from "moment";
import useFetchUserTestSuites from "../../hooks/useFetchUserTestSuites";
import PaginationComponent from "../../components/PaginationComponent";
import { getStatusText } from "../../helpers/textHelper";
import { addCommasToNumber } from "../../helpers/numberHelper";

export default function UserTestSuites() {
  const [pageNumber, setPageNumber] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));
  const { userId } = useParams();
  const id = +userId;
  const today = new Date().toISOString().slice(0, 10);

  const { data, isLoading, error } = useFetchUserTestSuites(
    id,
    pageNumber,
    startDate,
    endDate
  );

  console.log("data :>> ", data);
  const testsuiteData = data?.testCases;
  const testsuiteDataArray = testsuiteData?.data;

  const last_page = testsuiteData?.last_page;

  return (
    <div>
      <Card>
        <CardBody>
          <Row>
            <Col md="6">
              <CardTitle tag="h5">
                Test Suites Listing - {data?.testSuites?.total}
              </CardTitle>
              <CardSubtitle className="mb-2 text-muted" tag="h6">
                Overview of the User's Test Suites
              </CardSubtitle>
            </Col>

            <Col md="6">
              <Form>
                <Row>
                  <Col md="6">
                    <Form.Group className="mb-3" controlId="featureName">
                      <Form.Label>From</Form.Label>
                      <Form.Control
                        type="date"
                        name="name"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        max={today}
                        placeholder="Feature name"
                      />
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group className="mb-3" controlId="featureName">
                      <Form.Label>To</Form.Label>
                      <Form.Control
                        type="date"
                        name="name"
                        max={today}
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        placeholder="Feature name"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <Table
            className="no-wrap mt-3 align-middle"
            responsive
            bordered
            hover
          >
            <thead>
              <tr>
                <th>Test Suite Name</th>
                <th>Number of schedules</th>
                <th>Run count</th>
                <th>Date Created</th>
                <th>Last Run</th>
                <th>Last Run Status</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td>Loading...</td>
                </tr>
              ) : (
                testsuiteDataArray?.map((tdata, index) => (
                  <tr key={index} className="border-top">
                    <td>
                      <div className="d-flex align-items-center p-2">
                        <div className="ms-3">
                          <h6 className="mb-0">{tdata.name}</h6>
                        </div>
                      </div>
                    </td>
                    <td>{addCommasToNumber(tdata?.schedules_count)}</td>
                    <td>{addCommasToNumber(tdata?.run_count)}</td>
                    <td>
                      {moment(tdata?.created_at).utc().format("YYYY-MM-DD")}
                    </td>
                    <td>
                      {tdata?.last_run_at
                        ? moment(tdata?.last_run_at).utc().format("YYYY-MM-DD")
                        : "N/A"}
                    </td>
                    <td>{getStatusText(tdata.last_run_result?.status)}</td>
                  </tr>
                ))
              )}
              {error && <tr> {"An error has occurred: " + error.message} </tr>}
            </tbody>
          </Table>
        </CardBody>

        <PaginationComponent
          totalPages={last_page}
          currentPage={pageNumber}
          onPageChange={setPageNumber}
        />
      </Card>
    </div>
  );
}
