import { useQuery } from "react-query";
import { fetchSubscriptions } from "../api/subscriptions.api";

export default function useFetchSubscriptions(filters) {
  const fetchSubscription = useQuery({
    queryKey: ["subscriptions"],
    queryFn: async () => await fetchSubscriptions(filters),
    keepPreviousData: true,
  });

  return fetchSubscription;
}
