import { Table } from "reactstrap";
import moment from "moment";
import { abbreviateNumber, getPercentage } from "../../helpers/numberHelper";

const EnterpriseProjectTable = ({ projects }) => {
  const projectData = projects?.data;
  return (
    <>
      <Table className="no-wrap mt-3 align-middle" responsive bordered hover>
        <thead>
          <tr>
            <th>Project Name</th>
            <th>Total Test Cases</th>
            <th>Total Test Runs</th>
            <th>Members</th>
            <th>Created</th>
            <th>Updated</th>
          </tr>
        </thead>
        <tbody>
          {projectData?.map((project, index) => (
            <tr key={index} className="border-top">
              <td className="text-capitalize">
                <div className="d-flex flex-column">
                  <p>{project.name}</p>
                  <small className="text-capitalize fw-bold">
                    {project.type}
                  </small>
                </div>
              </td>
              <td>
                <div className="d-flex flex-column">
                  <p>
                    {abbreviateNumber(
                      project?.api_tests_count + project?.test_cases_count
                    )}
                  </p>

                  <div className="fw-bold d-flex gap-3">
                    <small>
                      Api: {abbreviateNumber(project?.api_tests_count)}
                    </small>
                    <small>
                      Web/Mobile: {abbreviateNumber(project?.test_cases_count)}
                    </small>
                  </div>
                </div>
              </td>

              <td>
                <div className="d-flex flex-column">
                  <p>
                    {abbreviateNumber(
                      project?.api_runs_count + project?.test_runs_count
                    )}{" "}
                    -
                    <span className="text-success">
                      {" "}
                      {getPercentage(
                        project?.api_runs_count + project?.test_runs_count,
                        project?.test_runs_passed_count
                      )}{" "}
                    </span>
                    /
                    <span className="text-danger">
                      {" "}
                      {getPercentage(
                        project?.api_runs_count + project?.test_runs_count,
                        project?.test_runs_failed_count
                      )}
                    </span>
                  </p>

                  <div className="fw-bold d-flex gap-3">
                    <small>
                      Api: {abbreviateNumber(project?.api_runs_count)}
                    </small>
                    <small>
                      Web/Mobile: {abbreviateNumber(project?.test_runs_count)}
                    </small>
                  </div>
                </div>
              </td>
              <td>{abbreviateNumber(project.collaborators_count)}</td>
              <td>{moment(project.created_at).utc().format("YYYY-MM-DD")}</td>
              <td>{moment(project.updated_at).utc().format("YYYY-MM-DD")}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default EnterpriseProjectTable;
