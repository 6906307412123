import { Row, Col } from "reactstrap";
import { useParams } from "react-router-dom";
import { useLocation, Link } from "react-router-dom";
import { useFetchEnterpriseDetails } from "../../hooks/useFetchEnterprise";
import SingleEnterprise from "../../components/enterprise/SingleEnterprise";

const EnterpriseDetails = () => {
  const { enterpriseId } = useParams();
  const id = Number(enterpriseId);
  const { data, isLoading, error } = useFetchEnterpriseDetails(id);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lastPage = queryParams.get("page") || 1;

  const enterprise = data?.data;

  return (
    <div className="cards_data">
      <Row>
        <h5 className="mb-3">Enterprise {id}</h5>
        <Link to={`/enterprises/?page=${lastPage}`}>
          Back to Enterprise List
        </Link>
        {isLoading ? (
          <Col className="loading_div"> Loading... </Col>
        ) : (
          <Col className="my-3">
            <SingleEnterprise enterprise={enterprise} />
          </Col>
        )}
        {error && (
          <Col>
            {" "}
            Oops an unexpected Error has while fetching this particular user,
            try again.{" "}
          </Col>
        )}
      </Row>
    </div>
  );
};

export default EnterpriseDetails;
