import { Row, Col } from "reactstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import { useFetchEnterpriseProjects } from "../../hooks/useFetchEnterprise";
import EnterpriseProjectTable from "../../components/dashboard/EnterpriseProjectTable";

const EnterpriseProjects = () => {
  const { enterpriseId } = useParams();
  const id = Number(enterpriseId);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lastPage = queryParams.get("page") || 1;

  const { data, isLoading, error } = useFetchEnterpriseProjects(id);

  const enterprise = data?.data?.company;
  const projects = data?.data?.projects;

  return (
    <div className="card">
      <div className="card-body">
        <Row>
          <h4 className="mb-3">
            <strong>{enterprise?.name} Projects</strong>
          </h4>

          <Link to={`/users/?page=${lastPage}`}>Back to Enterprise List</Link>

          {isLoading ? (
            <Col className="loading_div"> Loading... </Col>
          ) : (
            <Col>
              <EnterpriseProjectTable projects={projects} />
            </Col>
          )}
          {error && (
            <Col>
              {" "}
              Oops an unexpected Error has while fetching this particular user,
              try again.{" "}
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

export default EnterpriseProjects;
