export const abbreviateNumber = (number) => {
  if (!number) {
    return number;
  }
  if (number >= 1e12) {
    return (number / 1e12).toFixed(1) + "T";
  } else if (number >= 1e9) {
    return (number / 1e9).toFixed(1) + "B";
  } else if (number >= 1e6) {
    return (number / 1e6).toFixed(1) + "M";
  } else if (number >= 1e3) {
    return (number / 1e3).toFixed(1) + "K";
  } else {
    return number.toString();
  }
};

export const addCommasToNumber = (number) => {
  if (!number) {
    return number;
  }
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getPercentage = (total, part) => {
  if (total === 0) return 0;
  return ((part / total) * 100).toFixed(2);
};

export const getStatusText = (status) => {
  if (typeof status === "boolean") {
    return status ? "Success" : "Error";
  } else if (typeof status !== "undefined") {
    return status;
  }
  return "N/A";
};
