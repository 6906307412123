export const getStatusText = (status) => {
  if (typeof status === "boolean") {
    return status ? "Success" : "Error";
  } else if (typeof status !== "undefined") {
    return status;
  }
  return "N/A";
};

export const underscoreTitleCase = (input) => {
  if (!input) {
    return "";
  }
  return input
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};
