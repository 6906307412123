import metricssApiClient, { errorMessage } from "../utils/axios-utils";

const url = `/companies`;

const fetchEnterprise = async (
  page,
  paginate,
  searchQuery,
  selectedCategory
) => {
  try {
    const enterprise = await metricssApiClient().get(
      `${url}?${
        paginate
          ? `page=${page}${searchQuery ? `&search=${searchQuery}` : ""}`
          : "paginate=false"
      }${selectedCategory?.value ? `&type=${selectedCategory.value}` : ""}`
    );
    return enterprise?.data;
  } catch (error) {
    errorMessage(error);
  }
};

const createEnterprise = async (data) => {
  try {
    const newEnterprise = await metricssApiClient().post(`${url}`, data);
    return newEnterprise?.data;
  } catch (error) {
    errorMessage(error);
  }
};

const updateEnterprise = async (data) => {
  try {
    const res = await metricssApiClient().put(`${url}/settings`, { ...data });
    return res?.data;
  } catch (err) {
    errorMessage(err);
  }
};

const fetchEnterpriseMembers = async (companyId) => {
  try {
    const response = await metricssApiClient().get(
      `${url}/${companyId}/members`
    );
    return response?.data;
  } catch (error) {
    errorMessage(error);
  }
};

const fetchEnterpriseDetails = async (companyId) => {
  try {
    const response = await metricssApiClient().get(`${url}/${companyId}`);
    return response?.data;
  } catch (error) {
    errorMessage(error);
  }
};

const fetchEnterpriseMonthlyUsage = async (companyId, fromDate, toDate) => {
  try {
    const params = new URLSearchParams();
    if (fromDate) params.append("from_date", fromDate);
    if (toDate) params.append("to_date", toDate);

    const response = await metricssApiClient().get(
      `${url}/${companyId}/monthly-usage?${params.toString()}`
    );
    return response?.data;
  } catch (error) {
    errorMessage(error);
  }
};

const fetchEnterpriseProjects = async (companyId) => {
  try {
    const response = await metricssApiClient().get(
      `${url}/${companyId}/projects`
    );
    return response?.data;
  } catch (error) {
    errorMessage(error);
  }
};

const updateEnterpriseAvatar = async (data) => {
  try {
    const res = await metricssApiClient().post(`${url}/avatar`, data);
    return res?.data;
  } catch (err) {
    errorMessage(err);
  }
};

const removeEnterpriseAvatar = async (data) => {
  try {
    const res = await metricssApiClient().post(`${url}/avatar/remove`, {
      ...data,
    });
    return res?.data;
  } catch (err) {
    errorMessage(err);
  }
};

export {
  fetchEnterprise,
  createEnterprise,
  updateEnterprise,
  updateEnterpriseAvatar,
  removeEnterpriseAvatar,
  fetchEnterpriseMembers,
  fetchEnterpriseProjects,
  fetchEnterpriseDetails,
  fetchEnterpriseMonthlyUsage,
};
