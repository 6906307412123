import { useQuery } from "react-query";
import { findOneUserSchedules } from "../api/users.api";

export default function useFetchUserSchedules(
  userId,
  pageNumber,
  startDate,
  endDate
) {
  const projects = useQuery(
    ["userSchedules", userId, pageNumber, startDate, endDate],
    async () =>
      await findOneUserSchedules(userId, pageNumber, startDate, endDate),
    {
      keepPreviousData: true,
    }
  );
  return projects;
}
