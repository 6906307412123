import { useQuery } from "react-query";
import { findOneUserTestSuites } from "../api/users.api";

export default function useFetchUserTestSuites(
  userId,
  pageNumber,
  startDate,
  endDate
) {
  const projects = useQuery(
    ["userTestSuites", userId, pageNumber, startDate, endDate],
    async () =>
      await findOneUserTestSuites(userId, pageNumber, startDate, endDate),
    {
      keepPreviousData: true,
    }
  );
  return projects;
}
