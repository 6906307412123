import metricssApiClient, { errorMessage } from "../utils/axios-utils";

const url = `/subscription-plans`;

const fetchPlans = async () => {
  try {
    const plans = await metricssApiClient().get(`${url}?limit=15`);
    return plans?.data;
  } catch (error) {
    errorMessage(error);
  }
};

const addPlan = async (data) => {
  try {
    const newPlan = await metricssApiClient().post(`${url}`, data);
    return newPlan?.data;
  } catch (error) {
    errorMessage(error);
  }
};

const updatePlan = async (data) => {
  try {
    const updatePlan = await metricssApiClient().patch(`${url}`, data);
    return updatePlan?.data;
  } catch (error) {
    errorMessage(error);
  }
};

const subscribeToPlan = async (data) => {
  try {
    const subscribeToPlan = await metricssApiClient().post(
      `${url}/subscribe`,
      data
    );
    return subscribeToPlan?.data;
  } catch (error) {
    errorMessage(error);
  }
};

const removePlan = async (planId) => {
  const deletedPlan = await metricssApiClient().delete(`${url}/${planId}`);
  return deletedPlan?.data;
};

export { fetchPlans, addPlan, removePlan, updatePlan, subscribeToPlan };
