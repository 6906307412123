import React from "react";
import { addCommasToNumber } from "../../helpers/numberHelper";
import { Button, Modal, ModalBody, ModalHeader, Table } from "react-bootstrap";

const SubscriptionModal = ({
  subscription,
  subscriptionModalShow,
  setSubscriptionModalShow,
}) => {
  return (
    <Modal
      size="lg"
      show={subscriptionModalShow}
      onHide={() => setSubscriptionModalShow(false)}
      centered
    >
      <ModalHeader closeButton>
        <h4>{`${subscription?.name} Details`}</h4>
      </ModalHeader>
      <ModalBody>
        <div className="mb-3">
          <Table
            className="no-wrap mt-3 align-middle"
            responsive
            bordered
            hover
          >
            <tbody>
              <tr>
                <th>Max users</th>
                <td>{addCommasToNumber(subscription?.max_users)}</td>
              </tr>
              <tr>
                <th>Max parallel runs</th>
                <td>{addCommasToNumber(subscription?.max_parallel_runs)}</td>
              </tr>
              <tr>
                <th>Max monthly remote runs</th>
                <td>
                  {addCommasToNumber(subscription?.max_monthly_remote_runs)}
                </td>
              </tr>
              <tr>
                <th>Max mobile runtime</th>
                <td>{addCommasToNumber(subscription?.max_mobile_runtime)}</td>
              </tr>
              <tr>
                <th>Turbo Mode</th>
                <td>{subscription?.turbo_mode ? "True" : "False"}</td>
              </tr>

              <tr>
                <th>Monthly Price</th>
                <td>{addCommasToNumber(subscription?.monthly_price)}</td>
              </tr>

              <tr>
                <th>Annual Price</th>
                <td>{addCommasToNumber(subscription?.annual_price)}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </ModalBody>

      <Modal.Footer>
        <Button
          variant="warning"
          onClick={() => setSubscriptionModalShow(false)}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SubscriptionModal;
