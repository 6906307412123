import metricssApiClient, { errorMessage } from "../utils/axios-utils";

const url = `/subscriptions`;

const fetchSubscriptions = async (
  filters = {
    searchText: "",
    selectedPlan: "",
    status: "",
    subscriptionStartDate: "",
    subscriptionEndDate: "",
    expiryStartDate: "",
    expiryEndDate: "",
  }
) => {
  try {
    const {
      search,
      subscriptionPlanId,
      subscriptionFromDate,
      subscriptionToDate,
      expirationFromDate,
      expirationToDate,
      status,
    } = filters;

    console.log("search :>> ", search);
    console.log("filters :>> ", filters);

    const params = new URLSearchParams();
    if (search) params.append("search", search);
    if (status) params.append("status", status);

    if (subscriptionPlanId)
      params.append("subscription_plan_id", subscriptionPlanId);

    if (subscriptionFromDate)
      params.append("subscription_from_date", subscriptionFromDate);

    if (subscriptionToDate)
      params.append("subscription_to_date", subscriptionToDate);

    if (expirationFromDate)
      params.append("expiration_from_date", expirationFromDate);

    if (expirationToDate) params.append("expiration_to_date", expirationToDate);

    const subscriptions = await metricssApiClient().get(
      `${url}?limit=15&${params.toString()}`
    );
    return subscriptions?.data;
  } catch (error) {
    errorMessage(error);
  }
};

export { fetchSubscriptions };
