import { useQuery } from "react-query";
import { findOneUserApiSuites } from "../api/users.api";

export default function useFetchUserApiSuites(
  userId,
  pageNumber,
  startDate,
  endDate
) {
  const projects = useQuery(
    ["userApiSuites", userId, pageNumber, startDate, endDate],
    async () =>
      await findOneUserApiSuites(userId, pageNumber, startDate, endDate),
    {
      keepPreviousData: true,
    }
  );
  return projects;
}
