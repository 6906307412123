import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  fetchPlans,
  removePlan,
  subscribeToPlan,
  updatePlan,
} from "../api/plans.api";

export default function useFetchPlans() {
  const fetchPlan = useQuery({
    queryKey: ["plans"],
    queryFn: async () => await fetchPlans(),
    keepPreviousData: true,
  });

  return fetchPlan;
}

export function useUpdatePlan(data) {
  const queryClient = useQueryClient();

  const update = useMutation(
    (data) => {
      return updatePlan(data);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("plans");
      },
    }
  );

  return update;
}

export function useSubscribeToPlan(data) {
  const subscribe = useMutation((data) => {
    return subscribeToPlan(data);
  });

  return subscribe;
}

export function useDeletePlan(id) {
  const queryClient = useQueryClient();

  const deletePlan = useMutation(
    (id) => {
      return removePlan(id);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("plans");
      },
    }
  );

  return deletePlan;
}
