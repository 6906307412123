import { Table } from "reactstrap";
import {
  Button,
  FormControl,
  FormSelect,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from "react-bootstrap";
import Modals from "../Modals";
import useFetchPlan, {
  useDeletePlan,
  useSubscribeToPlan,
} from "../../hooks/useFetchPlans";
import { useEffect, useState } from "react";
import Select from "react-select";
import { useFetchEnterprise } from "../../hooks/useFetchEnterprise";
import { addCommasToNumber } from "../../helpers/numberHelper";

const PlansTable = ({ onEditPlan }) => {
  const { mutate: handleDelete } = useDeletePlan();
  const { isLoading, error, data } = useFetchPlan();
  const plans = data?.data;

  const [modalShow, setModalShow] = useState(false);
  const [subscribeModalShow, setSubscribeModalShow] = useState(false);
  const [modalValue, setModalValue] = useState();
  const [selectedPlan, setSelectedPlan] = useState();
  const [selectedEnterprise, setSelectedEnterprise] = useState();
  const [enterpriseOptions, setEnterpriseOptions] = useState();
  const [duration, setDuration] = useState(1);
  const [type, setType] = useState();

  const handleSelectChange = (selected) => {
    setSelectedEnterprise(selected);
  };

  const {
    mutate: subscribePlan,
    isLoading: isSubscribing,
    isSuccess: isSubscribeSuccess,
    isError: isSubscribeError,
    error: subscribeError,
  } = useSubscribeToPlan();

  const confirmDeletePlan = (e, planId) => {
    setModalValue(planId);
    if (modalShow === true) {
      setModalShow(false);
      if (e?.target.innerText === "Delete") {
        handleDelete(modalValue);
      }
    } else {
      setModalShow(true);
    }
  };

  const { data: enterprises } = useFetchEnterprise();

  useEffect(() => {
    if (enterprises) {
      const formattedOptions = enterprises?.data.map((enterprise) => ({
        value: enterprise?.id,
        label: enterprise?.name,
      }));

      setEnterpriseOptions(formattedOptions);
    } else {
      console.error("Fetched enterprises is fetched:", enterprises);
    }
  }, [enterprises]);

  const showSubscribeModal = (plan) => {
    setSelectedPlan(plan);
    setSubscribeModalShow(true);
  };

  const subscribe = () => {
    subscribePlan({
      subscription_plan_id: selectedPlan.id,
      company_id: selectedEnterprise.value,
      type: type,
      duration: duration,
    });
  };

  return (
    <>
      <Table className="no-wrap mt-3 align-middle" responsive bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Pricing</th>
            <th>Max Projects</th>
            <th>Max Users</th>
            <th>Max Parallel Runs</th>
            <th>Max Monthly Remote Runs</th>
            <th>Max Mobile Runtime</th>
            <th>Turbo Mode</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td>Loading...</td>
            </tr>
          ) : (
            plans?.map((plan, index) => (
              <tr key={index} className="border-top">
                <td>
                  {/* <NavLink className={"user_card"} to={`/plans/${plan.id}`}> */}
                  <h6>{plan.name}</h6>
                  {/* </NavLink> */}
                </td>
                <td>
                  <strong>Monthly: </strong> {addCommasToNumber(plan.monthly_price)} <br />
                  <strong>Annually: </strong> {addCommasToNumber(plan.annual_price)}
                </td>
                <td>{plan.max_projects}</td>
                <td>{plan.max_users}</td>
                <td>{addCommasToNumber(plan.max_parallel_runs)}</td>
                <td>{addCommasToNumber(plan.max_monthly_remote_runs)}</td>
                <td>{addCommasToNumber(plan.max_mobile_runtime)}</td>
                <td>{plan.turbo_mode ? "True" : "False"}</td>
                <td>
                  <Button
                    onClick={() => showSubscribeModal(plan)}
                    variant="outline-success"
                  >
                    <i className="bi bi-plus"></i>
                  </Button>
                  &nbsp;
                  <Button
                    onClick={() => onEditPlan(plan)}
                    variant="outline-warning"
                  >
                    <i className="bi bi-pencil"></i>
                  </Button>
                  &nbsp;
                  <Button
                    onClick={(e) => confirmDeletePlan(e, plan.id)}
                    variant="outline-danger"
                  >
                    <i className="bi bi-trash"></i>
                  </Button>
                </td>
              </tr>
            ))
          )}
          {error && (
            <tr>
              <td>{`Error: ${error.message}`}</td>
            </tr>
          )}
        </tbody>
      </Table>
      <Modals
        show={modalShow}
        onHide={(e) => confirmDeletePlan(e)}
        submitbutton="Delete"
        type="button"
        title="Delete Plan"
        btnvariant="danger"
      >
        Please confirm you want to delete this plan.
      </Modals>

      <Modal
        size="lg"
        show={subscribeModalShow}
        onHide={() => setSubscribeModalShow(false)}
        centered
      >
        <ModalHeader closeButton>
          <h4>{`Subscribe enterprise to ${selectedPlan?.name}`}</h4>
        </ModalHeader>
        <ModalBody>
          {isSubscribing && (
            <div className="d-flex justify-content-center mb-3">
              {" "}
              <Spinner />
            </div>
          )}

          {isSubscribeSuccess && (
            <div className="alert alert-success">Subscribed successfully</div>
          )}

          {isSubscribeError && (
            <div className="alert alert-warning">{subscribeError.message}</div>
          )}

          <div className="mb-3">
            <label>
              Please select the enterprise you want to subscribe to this plan.
            </label>
            <Select
              defaultValue={null}
              options={enterpriseOptions}
              value={selectedEnterprise}
              onChange={handleSelectChange}
              isSearchable
              placeholder="Select an enterprise"
            />
          </div>
          <div className="mb-3">
            <label>Type</label>
            <FormSelect
              name="type"
              defaultValue={"monthly"}
              onChange={(event) => setType(event.target.value)}
            >
              <option disabled>Select an option</option>
              <option value={"monthly"}>Monthly</option>
              <option value={"annually"}>Annually</option>
            </FormSelect>
          </div>

          <div className="mb-3">
            <label>Duration</label>
            <FormControl
              name="duration"
              onChange={(event) => setDuration(event.target.value)}
              defaultValue={1}
              type="number"
            />
          </div>
        </ModalBody>

        <Modal.Footer>
          <Button
            variant="warning"
            onClick={() => setSubscribeModalShow(false)}
          >
            Close
          </Button>
          <Button variant="dark" onClick={subscribe}>
            Subscribe
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PlansTable;
