import axios from "axios";
// import { staging } from '../config/config.json'

let authToken;
const baseURL = process.env.REACT_APP_API_URL;

// console.log('baseURL :>> ', baseURL);
const metricssApiClient = (data) => {
  authToken = JSON.parse(localStorage.getItem("token"));
  return axios.create({
    baseURL,
    maxBodyLength: Infinity,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      Authorization: `Bearer ${authToken}`,
    },
    // data: data,
    credentials: "include",
  });
};

const errorMessage = (error) => {
  const Actualmessage = error?.response?.data?.error;
  const serverError = error?.message;
  throw new Error(Actualmessage ?? serverError);
};

export default metricssApiClient;
export { errorMessage };
