import { useMutation, useQueryClient } from "react-query";
import { addPlan } from "../api/plans.api";

export default function usePlanForm() {
  const queryClient = useQueryClient();

  return useMutation(addPlan, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("plans");
    },
  });
}
