import { Table } from "reactstrap";
import {
  Button,
  Col,
  FormControl,
  FormLabel,
  FormSelect,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "react-bootstrap";
import { useState } from "react";
import useFetchSubscriptions from "../../hooks/useFetchSubscriptions";
import { NavLink } from "react-router-dom";
import { addCommasToNumber } from "../../helpers/numberHelper";
import PaginationComponent from "../PaginationComponent";
import SubscriptionModal from "./SubscriptionModal";
import useFetchPlans from "../../hooks/useFetchPlans";

const SubscriptionsTable = () => {
  const [planModalShow, setPlanModalShow] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState();
  const [filters, setFilters] = useState({
    searchText: "",
    selectedPlan: "",
    status: "",
    subscriptionStartDate: "",
    subscriptionEndDate: "",
    expiryStartDate: "",
    expiryEndDate: "",
  });

  const { isLoading, data, error, isError } = useFetchSubscriptions(filters);
  const subscriptions = data?.data?.data;
  const last_page = data?.data?.meta?.last_page;

  const { data: plansData } = useFetchPlans();
  const plans = plansData?.data;

  const showPlanModal = (subscription) => {
    setSelectedSubscription(subscription);
    setPlanModalShow(true);
  };

  const [pageNumber, setPageNumber] = useState(1);

  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };
  return (
    <>
      {isError && <div className="alert alert-warning">{error.message}</div>}

      <div className="d-flex justify-content-between">
        <h2>Subscriptions</h2>

        <Button onClick={() => setShowFilterModal(true)} variant="success">
          <i className="bi bi-filter"></i> Filter
        </Button>
      </div>
      <Table className="no-wrap mt-3 align-middle" responsive bordered hover>
        <thead>
          <tr>
            <th>Company</th>
            <th>Plan</th>
            <th>Plan Price</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td>Loading...</td>
            </tr>
          ) : (
            subscriptions?.map((subscription, index) => (
              <tr key={index} className="border-top">
                <td>
                  <NavLink
                    className={"user_card"}
                    to={`/enterprise/${subscription?.company?.id}`}
                  >
                    <h6>{subscription?.company?.name}</h6>
                  </NavLink>
                </td>
                <td>{subscription?.subscription_plan?.name}</td>
                <td>{addCommasToNumber(subscription?.price)}</td>
                <td>{subscription?.start_date}</td>
                <td>{subscription?.expiry_date}</td>
                <td>
                  <Button
                    onClick={() =>
                      showPlanModal(subscription?.subscription_plan)
                    }
                    variant="outline-success"
                  >
                    <i className="bi bi-eye"></i> View Plan
                  </Button>
                </td>
              </tr>
            ))
          )}
          {error && (
            <tr>
              <td>{`Error: ${error.message}`}</td>
            </tr>
          )}
        </tbody>
      </Table>

      {last_page > 1 && (
        <PaginationComponent
          totalPages={last_page}
          currentPage={pageNumber}
          onPageChange={handlePageChange}
        />
      )}

      <SubscriptionModal
        subscription={selectedSubscription}
        setPlanModalShow={setPlanModalShow}
        planModalShow={planModalShow}
      />

      <Modal
        size="lg"
        show={showFilterModal}
        onHide={() => setShowFilterModal(false)}
        centered
      >
        <ModalHeader closeButton>
          <h4>Filter</h4>
        </ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <Row>
              <Col md="12">
                <div className="form-group mb-3">
                  <FormLabel>Search by company name</FormLabel>
                  <FormControl
                    name="searchText"
                    placeholder="search"
                    value={filters.searchText}
                    onChange={handleFilterChange}
                  />
                </div>
              </Col>

              <Col md="6">
                <div className="form-group mb-3">
                  <FormLabel>Subscription Plan</FormLabel>
                  <FormSelect
                    name="selectedPlan"
                    value={filters.selectedPlan}
                    onChange={handleFilterChange}
                    aria-label="Select a Plan"
                  >
                    <option value="">Select a Plan</option>
                    {plans?.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </FormSelect>
                </div>
              </Col>

              <Col md="6">
                <div className="form-group mb-3">
                  <FormLabel>Status</FormLabel>
                  <FormSelect
                    name="status"
                    value={filters.status}
                    onChange={handleFilterChange}
                    aria-label="Select a Status"
                  >
                    <option value="">Select a Status</option>
                    <option value="active">Active</option>
                    <option value="expired">Expired</option>
                  </FormSelect>
                </div>
              </Col>

              <Col md="6">
                <div className="form-group mb-3">
                  <FormLabel>Subscription start date</FormLabel>
                  <FormControl
                    type="date"
                    name="subscriptionStartDate"
                    value={filters.subscriptionStartDate}
                    onChange={handleFilterChange}
                  />
                </div>
              </Col>

              <Col md="6">
                <div className="form-group mb-3">
                  <FormLabel>Subscription end date</FormLabel>
                  <FormControl
                    type="date"
                    name="subscriptionEndDate"
                    value={filters.subscriptionEndDate}
                    onChange={handleFilterChange}
                  />
                </div>
              </Col>

              <Col md="6">
                <div className="form-group mb-3">
                  <FormLabel>Expiry start date</FormLabel>
                  <FormControl
                    type="date"
                    name="expiryStartDate"
                    value={filters.expiryStartDate}
                    onChange={handleFilterChange}
                  />
                </div>
              </Col>

              <Col md="6">
                <div className="form-group mb-3">
                  <FormLabel>Expiry end date</FormLabel>
                  <FormControl
                    type="date"
                    name="expiryEndDate"
                    value={filters.expiryEndDate}
                    onChange={handleFilterChange}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </ModalBody>

        <Modal.Footer>
          <Button variant="warning" onClick={() => setShowFilterModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SubscriptionsTable;
