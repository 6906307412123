import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  fetchEnterprise,
  updateEnterprise,
  createEnterprise,
  updateEnterpriseAvatar,
  removeEnterpriseAvatar,
  fetchEnterpriseMembers,
  fetchEnterpriseProjects,
  fetchEnterpriseDetails,
  fetchEnterpriseMonthlyUsage,
} from "../api/enterprise";

export default function useFetchEnterprisePaginate(
  page,
  paginate,
  searchQuery,
  selectedCategory
) {
  const fetchAdmin = useQuery({
    queryKey: ["companies", page, searchQuery, selectedCategory.value],
    queryFn: async () =>
      await fetchEnterprise(page, paginate, searchQuery, selectedCategory),
    keepPreviousData: true,
  });

  return fetchAdmin;
}

export function useFetchEnterprise() {
  const fetchAdmin = useQuery({
    queryKey: ["companies"],
    queryFn: async () => await fetchEnterprise(),
    keepPreviousData: true,
  });

  return fetchAdmin;
}

export function useFetchEnterpriseDetails(companyId) {
  const fetchAnEnterprise = useQuery({
    queryKey: ["company-details", companyId],
    queryFn: async () => await fetchEnterpriseDetails(companyId),
    keepPreviousData: true,
  });

  return fetchAnEnterprise;
}

export function useFetchEnterpriseMonthlyUsage(companyId, fromDate, toDate) {
  const fetchAnEnterprise = useQuery({
    queryKey: ["company-details", companyId, fromDate, toDate],
    queryFn: async () => {
      return await fetchEnterpriseMonthlyUsage(companyId, fromDate, toDate);
    },
    keepPreviousData: true,
  });

  return fetchAnEnterprise;
}

export function useFetchEnterpriseMembers($companyId) {
  const data = useQuery({
    queryKey: ["companyId", $companyId],
    queryFn: async () => await fetchEnterpriseMembers($companyId),
    keepPreviousData: true,
  });

  return data;
}

export function useFetchEnterpriseProjects($companyId) {
  const data = useQuery({
    queryKey: ["companyProjects", $companyId],
    queryFn: async () => await fetchEnterpriseProjects($companyId),
    keepPreviousData: true,
  });

  return data;
}

export const useUpdateEnterprise = () => {
  const client = useQueryClient();

  return useMutation(updateEnterprise, {
    onSuccess: (data) => {
      client.invalidateQueries("companies");
    },
  });
};

export const useUpdateEnterpriseAvatar = () => {
  const client = useQueryClient();

  return useMutation(updateEnterpriseAvatar, {
    onSuccess: (data) => {
      client.invalidateQueries("companies");
    },
  });
};

export const useRemoveEnterpriseAvatar = () => {
  const client = useQueryClient();

  return useMutation(removeEnterpriseAvatar, {
    onSuccess: (data) => {
      client.invalidateQueries("companies");
    },
  });
};

export const useCreateEnterprise = () => {
  const client = useQueryClient();

  return useMutation(createEnterprise, {
    onSuccess: (data) => {
      client.invalidateQueries("companies");
    },
  });
};
