import { Alert, Card, CardBody, CardImg, CardTitle, Spinner } from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import { useState } from "react";
import { FormControl } from "react-bootstrap";
import { useFetchEnterpriseMonthlyUsage } from "../../hooks/useFetchEnterprise";
import SubscriptionModal from "../dashboard/SubscriptionModal";

const SingleEnterprise = ({ enterprise }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));
  const today = new Date().toISOString().slice(0, 10);

  const { data, isLoading, error, isSuccess, isError } =
    useFetchEnterpriseMonthlyUsage(enterprise?.id, startDate, endDate);

  const monthlyUsage = data?.data;

  const [subscriptionModalShow, setSubscriptionModalShow] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState();

  const showSubscribeModal = (subscription) => {
    setSelectedSubscription(subscription);
    setSubscriptionModalShow(true);
  };

  return (
    <>
      <Card>
        <CardBody className="p-4">
          {enterprise?.avatar && (
            <CardImg
              alt="Card image cap"
              className="my-2 img-fluid"
              style={{ width: "4rem" }}
              src={enterprise?.avatar}
            />
          )}
          <CardTitle tag="h3">
            <strong>{enterprise?.name}</strong>
          </CardTitle>

          <div className="d-flex gap-2">
            <small>
              <strong>Type: </strong>
              <span className="text-capitalize">{enterprise?.type}</span>
            </small>

            <small>
              <strong>Domain: </strong>
              <span className="text-capitalize">
                {enterprise?.domain ?? "N/A"}
              </span>
            </small>

            <small>
              <strong>Active Plan: </strong>
              <span className="text-capitalize">
                {enterprise?.active_subscription_plan ? (
                  <Link
                    to={"#"}
                    onClick={() =>
                      showSubscribeModal(
                        enterprise?.active_subscription_plan?.subscription_plan
                      )
                    }
                  >
                    {
                      enterprise?.active_subscription_plan?.subscription_plan
                        ?.name
                    }
                  </Link>
                ) : (
                  "N/A"
                )}
              </span>
            </small>
          </div>

          <div className="row my-4">
            <div className="col-md-6">
              <Card style={{ backgroundColor: "#eee" }}>
                <CardBody>
                  <CardTitle tag={"h5"} className="fw-semibold">
                    Settings
                  </CardTitle>

                  <div className="mt-3">
                    <div className="d-flex flex-column gap-2">
                      <small>
                        <strong>Allowed remote runs: </strong>
                        <span>{enterprise?.settings?.allowed_remote_runs}</span>
                      </small>

                      <small>
                        <strong>Readonly enabled: </strong>
                        <span>
                          {enterprise?.settings?.enable_readonly === 1
                            ? "Yes"
                            : "No"}
                        </span>
                      </small>

                      <small>
                        <strong>Max allowed projects: </strong>
                        <span>
                          {enterprise?.settings?.max_allowed_projects}
                        </span>
                      </small>

                      <small>
                        <strong>Max allowed users: </strong>
                        <span>{enterprise?.settings?.max_allowed_users}</span>
                      </small>

                      <small>
                        <strong>Max concurrency: </strong>
                        <span>{enterprise?.settings?.max_concurrency}</span>
                      </small>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>

            <div className="col-md-6">
              <Card style={{ backgroundColor: "#eee" }}>
                <CardBody>
                  <CardTitle tag={"h5"} className="fw-semibold">
                    <p>Monthly Usage</p>
                  </CardTitle>

                  <div className="d-flex gap-2">
                    <FormControl
                      type="date"
                      // name="name"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      max={today}
                      placeholder="Start date"
                    />
                    <FormControl
                      type="date"
                      // name="name"
                      max={today}
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      placeholder="End date"
                    />
                  </div>

                  {isLoading && <Spinner className="my-2" />}
                  {isError && <Alert className="my-2"> {error.message} </Alert>}

                  {isSuccess && (
                    <div className="mt-3">
                      <div className="d-flex flex-column gap-2">
                        <small>
                          <strong>Local runs: </strong>
                          <span>{monthlyUsage.localRuns}</span>
                        </small>

                        <small>
                          <strong>Projects: </strong>
                          <span>{monthlyUsage.projects}</span>
                        </small>

                        <small>
                          <strong>Remote runs: </strong>
                          <span>{monthlyUsage.remoteRuns}</span>
                        </small>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>

          <div className="card_links justify-content-end gap-2">
            <NavLink
              className={"btn btn-info"}
              to={`/enterprise/${enterprise?.id}/projects`}
            >
              Projects
            </NavLink>
            <NavLink
              className={"btn btn-info"}
              to={`/enterprise/${enterprise?.id}/members`}
            >
              Members
            </NavLink>
          </div>
        </CardBody>
      </Card>

      <SubscriptionModal
        subscription={selectedSubscription}
        setSubscriptionModalShow={setSubscriptionModalShow}
        subscriptionModalShow={subscriptionModalShow}
      />
    </>
  );
};

export default SingleEnterprise;
